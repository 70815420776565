<template>
  <div class="products">
    <div class="page-header">
      <h1>{{ $t('Product Management') }} > {{ $t('Bar Code & QR Code') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Products') }}</h2>
      </div>
      <ItemFilter :placeholder="$t('Search by product name')" default="productName" @search="searchItems">
        <el-option :label="$t('Product Name Search')" value="productName"></el-option>
      </ItemFilter>
      <div class="categories-filter">
        <span class="filter-label"> {{ $t('Categories') }}</span>
        <el-checkbox-group v-model="targetCategories" @change="onCheckboxChange">
          <el-checkbox v-if="langcode == 'zh'" :key="categorie.id" v-for="categorie in categories" :label="categorie.categorie_name_zh"></el-checkbox>
          <el-checkbox v-if="langcode == 'en'" :key="categorie.id" v-for="categorie in categories" :label="categorie.categorie_name_en"></el-checkbox>
        </el-checkbox-group>
      </div>

      <div class="product-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('Product ID') }}</th>
              <th scope="col">{{ $t('Photo') }}</th>
              <th scope="col">{{ $t('Product Name') }}</th>
              <th scope="col">{{ $t('Product Categories') }}</th>
              <th scope="col">{{ $t('QR Code')}}</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in products">
              <td>{{ product.reference_id }}</td>
              <td class="product-image-td">
                <div class="product-image-wrapper">
                  <img class="product-image img-fluid" v-if="getProductImage(product.product_images)" :src="getProductImage(product.product_images)" />
                  <img class="product-image img-fluid" v-else src="@/assets/no-image.jpg" />
                </div>
              </td>
              <td v-if="langcode === 'zh'">{{ product.product_name_zh }}</td>
              <td v-else>{{ product.product_name_en }}</td>
              <td>{{ getProductCategoires(product) }}</td>
              <td><img :src="`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${adminUrl}/product/edit/${product.id}`"/></td>
              <td>
                <div class="action-links">
                  <a class="action-link" :href="`${apiUrl}/product/barcode/${product.id}`">{{ $t('Print Barcode') }}</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Product from '@/lib/product';
import ItemFilter from '@/components/Filter.vue';

export default {
  name: 'Products',
  components: {
    ItemFilter,
  },
  mounted(){
    this.loadProducts();
    this.loadCategories();
  },
  methods: {
    searchItems(form){
      const searchField = {};
      let newItems = [];
      switch(form.filterTarget){
        case 'productName':
        if(this.langcode === 'zh'){
          searchField['product_name_zh'] = form.filterValue;
        }else{
          searchField['product_name_en'] = form.filterValue;
        }
        newItems = Common.filterItems(searchField, this.orignalProducts);
        this.searchProducts = newItems;
        this.products = newItems;
        break;
      }
    },
    onCheckboxChange(){
      const self = this; //Use self to replace this in filter function
      const targetCategories = self.targetCategories;
      if(self.searchProducts){
        const newItems = self.searchProducts.filter(function(item){
          let itemName = '';
          const itemCateogires = item.product_categories;
          for(let i = 0; i < itemCateogires.length; i++){
            const itemCateogire = itemCateogires[i];
            if(self.langcode === 'zh'){
              if(targetCategories.includes(itemCateogire.categorie_name_zh)){
                return true;
              }
            }else{
              if(targetCategories.includes(itemCateogire.categorie_name_en)){
                return true;
              }
            }
          }
        });
        self.products = newItems;
      }else{
        const newItems = self.orignalProducts.filter(function(item){
          let itemName = '';
          const itemCateogires = item.product_categories;
          for(let i = 0; i < itemCateogires.length; i++){
            const itemCateogire = itemCateogires[i];
            if(self.langcode === 'zh'){
              if(targetCategories.includes(itemCateogire.categorie_name_zh)){
                return true;
              }
            }else{
              if(targetCategories.includes(itemCateogire.categorie_name_en)){
                return true;
              }
            }
          }
        });
        self.products = newItems;
      }
      //If do not had any selected categoires ,reset it
      if(targetCategories.length === 0 ){
        if(self.searchProducts){
          self.products = self.searchProducts;
        }else{
          self.products = self.orignalProducts;
        }
      }
    },
    async onTabChange(){
      const activeName = this.activeName;
      if(activeName !== 'All'){
        this.loadLimitProducts(activeName);
      }else{
        this.products = this.orignalProducts;
      }
    },
    getProductCategoires(product){
      const productCategories = [];
      for(let i = 0; i < product.product_categories.length; i++){
        const productCategorie = product.product_categories[i];
        if(productCategorie){
          if(this.langcode === 'zh'){
            productCategories.push(productCategorie.categorie_name_zh);
          }else{
            productCategories.push(productCategorie.categorie_name_en);
          }
        }
      }
      const productCategoriesString = productCategories.join(', ');
      if(!productCategoriesString){
        return '';
      }
      return productCategoriesString
    },
    getProductImage(imageField){
      if(imageField){
        const images = JSON.parse(imageField);
        const image = images[0];
        return image;
      }
    },
    async loadLimitProducts(type){
      const searchFields = {
        'product_published': '',
      }
      if(type === 'Published'){
        if(this.searchProducts){
          const newItems = this.searchProducts.filter(function(product){
            return product['product_published'] == 1;
          });
          this.products = newItems;
        }else{
          const newItems = this.orignalProducts.filter(function(product){
            return product['product_published'] == 1;
          });
          this.products = newItems;
        }
      }
      if(type === 'Unpublished'){
        if(this.searchProducts){
          const newItems = this.searchProducts.filter(function(product){
            return product['product_published'] == 0;
          });
          this.products = newItems;
        }else{
          const newItems = this.orignalProducts.filter(function(product){
            return product['product_published'] == 0;
          });
          this.products = newItems;
        }
      }
      if(type === 'All'){
        if(this.searchProducts){
          this.products = searchProducts;
        }else{
          this.products = orignalProducts;
        }
      }
    },
    async loadProducts(){
      try{
        const loginInfo = Common.getLoginInfo();
        const products = await Product.loadAllProducts(this.apiUrl, loginInfo);
        this.orignalProducts = products;
        this.products = products;
      }catch(err){
        console.log(err);
      }
    },
    async loadCategories(){
      const loginInfo = Common.getLoginInfo();
      try{
        const categories = await Product.loadAllCategories(this.apiUrl, loginInfo);
        this.categories = categories;
      }catch(err){
        console.log(err);
      }
    },
  },
  data(){
    return {
      activeName: 'All',
      products: [],
      searchProducts: null, //Keep the tab result
      orignalProducts: [],
      categories: [],
      targetCategories: [],
    }
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
    adminUrl: state => state.adminUrl,
  }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.categories-filter{
  padding-left: 20px;
  font-weight: bold;
  margin-bottom: 50px;
  display: flex;
  .filter-label{
    margin-right: 20px;
  }
}
.product-image-wrapper{
  width: 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  .product-image{
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.product-image-td{
  width: 20%;
}
</style>
